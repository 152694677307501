import { AccountBox, ChevronLeft, ContactSupport, Login, Home, Settings } from "@mui/icons-material";
import { Box, Divider, Drawer, IconButton, MenuItem, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/slices/user";
import InfoIcon from "@mui/icons-material/Info";
import { HEADER_HEIGHT } from "./Header";

interface MenuProps {
  open: boolean;
  onClose: () => void;
}

export default function Menu({ open, onClose }: MenuProps) {
  const url = window.location.pathname;
  const dispatch = useDispatch();
  const loggedInUserId = useSelector((state: any) => state.user.userId);
  const navigate = useNavigate();

  function handleLogout() {
    dispatch(logoutUser());
    onClose();
  }

  const handleNavigate = (path: string) => {
    onClose();
    setTimeout(() => {
      navigate(path);
    }, 150);
  };

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Box
        sx={{
          width: { xs: "99vw", sm: "20vw" },
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={2}
          boxSizing={"border-box"}
          height={HEADER_HEIGHT}
          boxShadow={3}
        >
          <Typography variant="h5">Menu</Typography>
          <IconButton onClick={onClose}>
            <ChevronLeft fontSize="large" />
          </IconButton>
        </Stack>
        <Box sx={{ flex: 1, overflowY: "auto" }}>
          <Stack p={2}>
            {
              <MenuItem onClick={() => handleNavigate("/")} selected={url === "/"}>
                <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
                  <Home />
                  <Typography>Home Page</Typography>
                </Stack>
              </MenuItem>
            }
            {loggedInUserId && (
              <MenuItem onClick={() => handleNavigate("/user")} selected={url === "/user"}>
                <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
                  <AccountBox />
                  <Typography>My Profile</Typography>
                </Stack>
              </MenuItem>
            )}
            <MenuItem
              component="a"
              href="https://www.shockemcomics.com/index"
              target="_blank" // Opens in new tab
              rel="noopener noreferrer" // Security best practice for external links
              onClick={onClose}
            >
              <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
                <InfoIcon />
                <Typography>About Us</Typography>
              </Stack>
            </MenuItem>
            <MenuItem onClick={() => handleNavigate("/account-settings")} selected={url === "/account-settings"}>
              <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
                <Settings />
                <Typography>Account Settings</Typography>
              </Stack>
            </MenuItem>
            {/* <MenuItem component={Link} to="/leaderBoard" selected={url === "/leaderBoard"} onClick={onClose}>
              <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
                <LeaderboardIcon />
                <Typography>LeaderBoard</Typography>
              </Stack>
            </MenuItem>
            <MenuItem component={Link} to="/reward-a-friend" selected={url === "/reward-a-friend"} onClick={onClose}>
              <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
                <Collections />
                <Typography>Reward A Friend</Typography>
              </Stack>
            </MenuItem> */}
            <MenuItem selected={url === "/contact-us"} onClick={() => handleNavigate("/contact-us")}>
              <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
                <ContactSupport />
                <Typography>Contact Us</Typography>
              </Stack>
            </MenuItem>
            {/* <MenuItem component={Link} to="/item/test" onClick={onClose}>
              <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
                <Collections />
                <Typography>Item Page</Typography>
              </Stack>
            </MenuItem> */}
            <Divider />
            {!loggedInUserId && (
              <React.Fragment>
                <MenuItem onClick={() => handleNavigate("/login")} selected={url === "/login"}>
                  <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
                    <Login />
                    <Typography>Login</Typography>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={() => handleNavigate("/signup")} selected={url === "/signup"}>
                  <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
                    <AppRegistrationIcon />
                    <Typography>Register</Typography>
                  </Stack>
                </MenuItem>
              </React.Fragment>
            )}
            {loggedInUserId && (
              <React.Fragment>
                <MenuItem onClick={handleLogout}>
                  <Stack direction={"row"} gap={1} sx={{ textDecoration: "none", color: "inherit" }}>
                    <Login />
                    <Typography>Logout</Typography>
                  </Stack>
                </MenuItem>
              </React.Fragment>
            )}
          </Stack>
        </Box>
        {/* <Box
          sx={{
            mt: "auto",
            p: 2,
            borderTop: 1,
            borderColor: "divider",
            bgcolor: "background.paper",
          }}
        >
          <Box>
            <SocialIcon />
          </Box>
          <Box sx={{ mt: 2, fontSize: "0.9rem" }}>
            <Copyright />
          </Box>
        </Box> */}
      </Box>
    </Drawer>
  );
}
