export default function addTrackJSScript() {
  // Create and add Request Metrics script
  const rmScript = document.createElement("script");
  rmScript.innerHTML = `
      (function(t,e,n,r){function a(){return e&&e.now?e.now():null}if(!n.version){n._events=[];n._errors=[];n._metadata={};n._urlGroup=null;window.RM=n;n.install=function(e){n._options=e;var a=t.createElement("script");a.async=true;a.crossOrigin="anonymous";a.src=r;var o=t.getElementsByTagName("script")[0];o.parentNode.insertBefore(a,o)};n.identify=function(t,e){n._userId=t;n._identifyOptions=e};n.sendEvent=function(t,e){n._events.push({eventName:t,metadata:e,time:a()})};n.setUrlGroup=function(t){n._urlGroup=t};n.track=function(t,e){n._errors.push({error:t,metadata:e,time:a()})};n.addMetadata=function(t){n._metadata=Object.assign(n._metadata,t)}}})(document,window.performance,window.RM||{},"https://cdn.requestmetrics.com/agent/current/rm.js");
      window.RM.install({
        token: "q2jh8mi:y7aa5qe"
      });
    `;
  document.head.appendChild(rmScript);

  // Create and add TrackJS script
  const trackJsScript = document.createElement("script");
  trackJsScript.src = "https://cdn.trackjs.com/agent/v3/latest/t.js";
  trackJsScript.async = true;

  // Create TrackJS configuration script
  const trackJsConfigScript = document.createElement("script");
  trackJsConfigScript.innerHTML = `
      window.TrackJS && TrackJS.install({
        token: "25c33e907483479d8f032aec79ff306d",
        application: "shockem"
      });
    `;

  // Add TrackJS scripts
  document.head.appendChild(trackJsScript);
  trackJsScript.onload = () => {
    document.head.appendChild(trackJsConfigScript);
  };

  // Cleanup function
  return () => {
    document.head.removeChild(rmScript);
    document.head.removeChild(trackJsScript);
    if (trackJsConfigScript.parentNode) {
      document.head.removeChild(trackJsConfigScript);
    }
  };
  // Empty dependency array means this runs once on mount
}
