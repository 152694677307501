import { Stack } from "@mui/material";
import Copyright from "./Copyright";
import SocialIcon from "./SocialIcon";

export default function Footer({ showFooterSocialIcons = true }: { showFooterSocialIcons?: boolean }) {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      bgcolor={"white"}
      maxWidth={"100%"}
      boxSizing={"border-box"}
      sx={{
        minHeight: { xs: "6rem", sm: "5rem" },
        width: "100%",
        boxShadow: 5,
        gap: { xs: 2, sm: 1 },
        p: { xs: 5, sm: 3 },
      }}
      position={"relative"}
      bottom={0}
    >
      {showFooterSocialIcons && <SocialIcon />}
      <Copyright />
    </Stack>
  );
}
