import BaseAPI from "./BaseApi";

class SignupApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/user", cache: false });
  }

  async signUpUser(
    email: string,
    password: string,
    userName: string,
    firstName: string,
    lastName: string,
    skipVerification?: Boolean,
    stepProgress?: any
  ) {
    try {
      const result = await this.axios.post("/register", {
        email,
        password,
        userName,
        firstName,
        lastName,
        skipVerification,
        stepProgress,
      });
      if (!result.data) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      if (error?.response?.data?.message) throw new Error(error?.response?.data?.message);
      throw new Error("Error in AgentApi.signinAgent: " + error.message);
    }
  }
}

export const signupApi = new SignupApi();
