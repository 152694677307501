import { Box, Button, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { contactUsApi } from "../apis/ContactUsApi";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import BannerHeader from "../components/common/BannerHeader";
import orangeBanner from "../assets/images/orange-banner.png";
import contactUsbannerIcon from "../assets/images/contact-us-banner-icon.png";
import messageIconsBackground from "../assets/images/message-icons-background.png";
import { StyledButton } from "../styles/StyledComponents";

export default function ContactUs() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  async function handleFormSubmit() {
    try {
      await contactUsApi.sendContactUsMessage(formData);
      dispatch(openSnackbar({ message: "Message saved successfully", severity: "success" }));
    } catch (e: any) {
      dispatch(openSnackbar({ message: "Failed to save message", severity: "error" }));
    }
  }

  return (
    <Stack sx={{ alignItems: "center", gap: "1rem" }}>
      <BannerHeader
        bannerDecoration={messageIconsBackground}
        bannerIcon={contactUsbannerIcon}
        bannerText="Contact Us"
      />

      <Typography
        textAlign={"center"}
        p={"0.5rem"}
        sx={{
          fontSize: { xs: "1rem", sm: "1.2rem" },
          lineHeight: 1,
        }}
        gutterBottom
      >
        If you have any questions or are experiencing any issues, please fill out the form below and click "SUBMIT".
      </Typography>

      <Box
        sx={{
          width: { xs: "90%", sm: "25rem" },
          p: "1rem 2rem",
          mb: "1rem",
          boxSizing: "border-box",
          gap: "1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "1rem",
        }}
        component={Paper}
      >
        <Typography
          color="primary.main"
          textAlign={"center"}
          sx={{
            fontSize: { xs: "1rem", sm: "1.2rem" },
            lineHeight: 1,
            mb: "0rem",
          }}
          gutterBottom
        >
          How can we help?
        </Typography>

        <TextField
          label="YOUR NAME"
          variant="outlined"
          name="name"
          fullWidth
          size="small"
          onChange={handleChange}
          inputProps={{ sx: { fontSize: { xs: "0.875rem", sm: "1rem" } } }}
          InputLabelProps={{ sx: { fontSize: { xs: "0.875rem", sm: "1rem" } } }}
        />
        <TextField
          label="EMAIL ADDRESS"
          variant="outlined"
          name="email"
          fullWidth
          size="small"
          onChange={handleChange}
          inputProps={{ sx: { fontSize: { xs: "0.875rem", sm: "1rem" } } }}
          InputLabelProps={{ sx: { fontSize: { xs: "0.875rem", sm: "1rem" } } }}
        />
        <TextField
          label="SUBJECT"
          variant="outlined"
          name="subject"
          fullWidth
          size="small"
          onChange={handleChange}
          inputProps={{ sx: { fontSize: { xs: "0.875rem", sm: "1rem" } } }}
          InputLabelProps={{ sx: { fontSize: { xs: "0.875rem", sm: "1rem" } } }}
        />
        <TextField
          label="MESSAGE"
          variant="outlined"
          name="message"
          fullWidth
          multiline
          rows={3}
          size="small"
          onChange={handleChange}
          inputProps={{ sx: { fontSize: { xs: "0.875rem", sm: "1rem" } } }}
          InputLabelProps={{ sx: { fontSize: { xs: "0.875rem", sm: "1rem" } } }}
        />
        <StyledButton
          variant="contained"
          sx={{
            marginTop: "0rem",
            lineHeight: 1,
            fontSize: { xs: "0.875rem", sm: "1rem" },
          }}
          onClick={handleFormSubmit}
        >
          SUBMIT
        </StyledButton>
      </Box>
    </Stack>
  );
}
