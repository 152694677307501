import { useState } from "react";
import { Card, CardContent, Avatar, Typography, Rating, Box, Button, IconButton } from "@mui/material";
import { Review } from "./CustomerReviews";
import { FaDiscord, FaFacebook } from "react-icons/fa";
import { discordColor } from "../../configs/muiTheme";

interface ReviewCardProps {
  review: Review;
}

export default function ReviewCard({ review }: ReviewCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 150; // Characters to show before truncating
  const shouldTruncate = review.review.length > maxLength;

  const displayText = isExpanded
    ? review.review
    : shouldTruncate
    ? `${review.review.slice(0, maxLength)}...`
    : review.review;

  const getAvatarColor = (initial: string) => {
    const colors = [
      "#FF6B6B", // Red
      "#4ECDC4", // Teal
      "#45B7D1", // Blue
      "#96CEB4", // Green
      "#FFEEAD", // Yellow
      "#D4A5A5", // Pink
      "#9B59B6", // Purple
      "#3498DB", // Light Blue
    ];

    // Use the initial's char code to pick a color
    const colorIndex = initial.charCodeAt(0) % colors.length;
    return colors[colorIndex];
  };

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardContent
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
        }}
      >
        <Typography
          sx={{
            mb: "1.1rem",
            flex: 1,
            fontSize: "1.1rem",
            whiteSpace: "pre-wrap",
          }}
        >
          {displayText}
          {shouldTruncate && (
            <Button
              onClick={() => setIsExpanded(!isExpanded)}
              sx={{
                display: "inline",
                fontSize: "1rem",
                p: 0,
                minWidth: "auto",
                textTransform: "none",
                color: "secondary.main",
                "&:hover": {
                  backgroundColor: "transparent",
                  opacity: 0.8,
                },
              }}
            >
              {isExpanded ? "Show Less" : "Read More"}
            </Button>
          )}
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Avatar
            src={review.image || ""}
            sx={{
              width: "4rem",
              height: "4rem",
              fontSize: "1.1rem",
              bgcolor: !review.image ? getAvatarColor(review.name.charAt(0)) : undefined,
            }}
          >
            {!review.image && review.name.charAt(0).toUpperCase()}
          </Avatar>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              gap: "0.4rem",
            }}
          >
            <Rating
              value={review.rating}
              readOnly
              size="medium"
              sx={{
                p: "0",
                "& .MuiRating-icon": {
                  fontSize: "1.5rem",
                },
              }}
            />
            {/* <Typography color="text.secondary" sx={{ fontSize: "1rem", lineHeight: "1.1" }}>
              {review.date}
            </Typography> */}
            <Box sx={{ display: "flex", gap: 1 }}>
              {review.facebook && (
                <IconButton
                  href={`https://facebook.com/${review.facebook}`}
                  target="_blank"
                  size="small"
                  sx={{
                    color: "#1877F2", // Facebook blue
                    padding: "0.1rem",
                    borderRadius: "1rem",
                    backgroundColor: "rgba(24, 119, 242, 0.1)",
                    "&:hover": {
                      backgroundColor: "rgba(24, 119, 242, 0.2)",
                    },
                  }}
                >
                  <FaFacebook />
                  <Typography
                    variant="body2"
                    sx={{
                      ml: 0.5,
                      color: "text.secondary",
                      fontSize: "0.9rem",
                    }}
                  >
                    {review.facebook}
                  </Typography>
                </IconButton>
              )}

              {review.discord && (
                <IconButton
                  href={`https://discord.com/users/${review.discord}`}
                  target="_blank"
                  size="small"
                  sx={{
                    color: discordColor,
                    padding: "0.1rem",
                    borderRadius: "1rem",
                    backgroundColor: "rgba(88, 101, 242, 0.1)",
                    "&:hover": {
                      backgroundColor: "rgba(88, 101, 242, 0.2)",
                    },
                  }}
                >
                  <FaDiscord />
                  <Typography
                    variant="body2"
                    sx={{
                      ml: 0.5,
                      color: "text.secondary",
                      fontSize: "0.9rem",
                    }}
                  >
                    {review.discord}
                  </Typography>
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
