export function validatePassword(password: string, confirmPassword: string) {
  const passwordRequirements = {
    length: password.length >= 8,
    number: /\d/.test(password),
  };
  const isPasswordValid = passwordRequirements.length && passwordRequirements.number && password === confirmPassword;
  return {
    isPasswordValid,
    passwordRequirements,
  };
}

export function validateUserName(username: string) {
  const regex = /^[A-Za-z\d]{2,16}$/;
  return regex.test(username);
}

export function validateEmail(email: string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
}

export function validateSocialMediaLink(link: string) {
  const urlRegex = /^(https?:\/\/)?[\w.-]+(\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;
  return urlRegex.test(link);
}

export function validateName(name: string): { isValid: boolean; error: string } {
  if (!name || name.trim() === "") {
    return { isValid: false, error: "Name is required" };
  }

  if (name.length < 2) {
    return { isValid: false, error: "Name must be at least 2 characters long" };
  }

  if (name.length > 50) {
    return { isValid: false, error: "Name cannot exceed 50 characters" };
  }

  if (!/^[a-zA-Z\s]+$/.test(name)) {
    return { isValid: false, error: "Name can only contain letters and spaces" };
  }

  return { isValid: true, error: "" };
}

export function validateUserNameRequirements(username: string) {
  const usernameRequirements = {
    length: username.length >= 2 && username.length <= 16,
    validChars: /^[A-Za-z0-9]*$/.test(username),
    notEmpty: username.length > 0,
  };

  const isUsernameValid =
    usernameRequirements.length && usernameRequirements.validChars && usernameRequirements.notEmpty;

  return {
    isUsernameValid,
    usernameRequirements,
  };
}
