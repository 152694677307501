import { production } from "../configs/settings";
import BaseAPI from "./BaseApi";

class ScratchCardApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/scratchCard", cache: false });
  }

  async claimScratchCard({ scratchCardId, userId }: { scratchCardId: string; userId: string }): Promise<any> {
    try {
      const result = await this.axios.post("/claimReward", { scratchCardId, ownerUserId: userId });
      if (!result.data || !result.data?.success || !result.data?.scratchCardDetails)
        throw new Error("Invalid response from server");
      return result.data.scratchCardDetails;
    } catch (error: any) {
      throw new Error("Error in ScratchCardApi.claimScratchCard: " + error.message);
    }
  }

  async getScratchCardDetails({ fullCode, scratchCardId }: { fullCode?: string; scratchCardId?: string }) {
    try {
      if (!scratchCardId && !fullCode) return null;

      const result = await this.axios.get("/details", {
        params: { fullCode, scratchCardId },
      });
      if (!result.data || !result.data.scratchCardDetails) throw new Error("Invalid response from server");
      return result.data.scratchCardDetails;
    } catch (error: any) {
      const response = error?.response?.data?.message || "No response";
      if (!production)
        console.error("@ScratchCardApi.getScratchCardDetails:", { response: response }, { error: error.message });
      throw new Error(`@ScratchCardApi.getScratchCardDetails: ${response}`);
    }
  }

  async redeemScratchCard({ ownerUserId, scratchCardId }: { ownerUserId: string; scratchCardId: string }) {
    try {
      const result = await this.axios.post("/redeem", {
        ownerUserId,
        scratchCardId,
      });
      if (!result.data || !result.data.updatedScratchCardDetails) throw new Error("Invalid response from server");
      return result.data.updatedScratchCardDetails;
    } catch (error: any) {
      const response = error?.response?.data?.message || "No response";
      if (!production)
        console.error("@ScratchCardApi.claimScratchCard:", { response: response }, { error: error.message });
      throw new Error(`@ScratchCardApi.claimScratchCard: ${response}`);
    }
  }

  async getRewardForScratchCard({ scratchCardId }: { scratchCardId: string }) {
    try {
      if (!scratchCardId) return null;
      const result = await this.axios.post("/reward", {
        scratchCardId,
      });
      if (!result.data || !result.data.scratchCardDetails) throw new Error("Invalid response from server");
      return result.data.scratchCardDetails;
    } catch (error: any) {
      const response = error?.response?.data?.message || "No response";
      if (!production)
        console.error("@ScratchCardApi.getRewardForScratchCard:", { response: response }, { error: error.message });
      throw new Error(`@ScratchCardApi.getRewardForScratchCard: ${response}`);
    }
  }

  async updateRewardDisplayedScratchCard({
    scratchCardId,
    ownerUserId,
  }: {
    scratchCardId: string;
    ownerUserId: string;
  }) {
    try {
      if (!scratchCardId) return null;

      const result = await this.axios.post("/display", {
        scratchCardId,
        ownerUserId,
      });

      if (!result.data || !result.data.scratchCardDetails) throw new Error("Invalid response from server");
      return result.data.scratchCardDetails;
    } catch (error: any) {
      const response = error?.response?.data?.message || "No response";
      if (!production)
        console.error(
          "@ScratchCardApi.updateRewardDisplayedScratchCard:",
          { response: response },
          { error: error.message }
        );
      throw new Error(`@ScratchCardApi.updateRewardDisplayedScratchCard: ${response}`);
    }
  }

  async getPaginatedScratchCardUsers({
    scratchCardIds,
    ownerUserIds,
    fromTimeStamp,
    toTimeStamp,
    status,
    limit,
    page,
    sortKey,
    sortDirection,
    total = false,
    populate,
  }: {
    scratchCardIds?: string[];
    ownerUserIds?: string[];
    fromTimeStamp?: number;
    toTimeStamp?: number;
    status?: string | string[];
    limit?: number;
    page?: number;
    sortKey?: string;
    sortDirection?: string;
    total?: boolean;
    populate?: boolean;
  }): Promise<any> {
    try {
      const result = await this.axios.get("/scratchCardUsers", {
        params: {
          scratchCardIds,
          ownerUserIds,
          fromTimeStamp,
          toTimeStamp,
          status,
          limit,
          page,
          sortKey,
          sortDirection,
          total,
          populate,
        },
      });
      if (!result.data || !result.data?.success || !result.data?.scratchCardUsers)
        throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in ScratchCardUserApi.getPaginatedScratchCardUsers: " + error.message);
    }
  }

  async claimFreePromoCode({
    ownerUserId,
    scratchCardFullCode,
  }: {
    ownerUserId: string;
    scratchCardFullCode: string;
  }): Promise<any> {
    try {
      const result = await this.axios.post("/claimFreePromoCode", {
        ownerUserId,
        scratchCardFullCode,
      });
      if (!result.data || !result.data.success || !result.data.scratchCardUserDetails) {
        throw new Error("Invalid response from server");
      }
      return result.data.scratchCardUserDetails;
    } catch (error: any) {
      if (error?.response?.status === 409) {
        return null;
      }

      const responseMessage = error?.response?.data?.message || "No response";
      if (!production)
        console.error("@ScratchCardApi.claimFreePromoCode:", { response: responseMessage }, { error: error.message });
      throw new Error(`Error in ScratchCardApi.claimFreePromoCode: ${responseMessage}`);
    }
  }

  async getUserScratchCardsCount({ type }: { type: string }): Promise<number> {
    try {
      const result = await this.axios.get("/userScratchCardsCount", {
        params: { type },
      });

      if (!result.data || typeof result.data.count !== "number") {
        throw new Error("Invalid response from server");
      }

      return result.data.count;
    } catch (error: any) {
      const response = error?.response?.data?.message || "No response";
      if (!production)
        console.error("@ScratchCardApi.getUserScratchCardsCount:", { response }, { error: error.message });
      throw new Error(`@ScratchCardApi.getUserScratchCardsCount: ${response}`);
    }
  }
}

export const scratchCardApi = new ScratchCardApi();
