import React from "react";
import { Box, Typography, Container, Paper, Button } from "@mui/material";
import { loginApi } from "../../apis/LoginApi";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import { StyledButton } from "../../styles/StyledComponents";
import { production } from "../../configs/settings";

const EmailSentMessage = ({ email, userId }: { userId: string; email: string }) => {
  const dispatch = useDispatch();

  const handleResendEmail = async () => {
    try {
      await loginApi.sendEmailForSignupVerification({ userId: userId });
      dispatch(openSnackbar({ message: "Email sent successfully", severity: "success" }));
    } catch (error: any) {
      if (!production) console.error(error);
      dispatch(openSnackbar({ message: "Resend failed! Please try again later", severity: "error" }));
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 6 }}>
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            Email Verification Sent
          </Typography>
          <Typography variant="body1">
            We've sent a verification email to <strong>{email}</strong>. Please check your inbox and click on the
            verification link to verify your email address.
          </Typography>
          <Typography variant="body2" sx={{ marginTop: 2 }}>
            If you haven't received the email, check your spam folder or request a new one.
          </Typography>
          <StyledButton variant="contained" onClick={() => handleResendEmail()} sx={{ marginTop: 2, fontSize: "1rem" }}>
            Resend Email
          </StyledButton>
        </Box>
      </Paper>
    </Container>
  );
};

export default EmailSentMessage;
