export enum scratchCardStatusEnum {
  Created = "created",
  Active = "active",
}

export enum scratchCardUserStatusEnum {
  RewardAssigned = "rewardAssigned",
  Redeemed = "redeemed",
  Claimed = "claimed",
}

export enum scratchCardTypeEnum {
  PhysicalReferral = "physical-referral",
  Physical = "physical",
  PhysicalTest = "physical-test",
  PromoReferral = "promo-code-referral",
  PromoCode = "promo-code",
}
