import { Box } from "@mui/material";
import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { HEADER_HEIGHT } from "../common/Header";

export default function CommonLayoutProvider({
  children,
  authBasedRedirect = true,
  showFooter = true,
  showFooterSocialIcons = true,
}: {
  children: React.ReactNode;
  authBasedRedirect?: boolean;
  showFooter?: boolean;
  showFooterSocialIcons?: boolean;
}) {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header authBasedRedirect={authBasedRedirect} />
      <Box height={HEADER_HEIGHT} />

      <Box component="main" flexGrow={1}>
        {children}
      </Box>
      {showFooter && <Footer showFooterSocialIcons={showFooterSocialIcons} />}
    </Box>
  );
}
