import { useEffect } from "react";
import { setUser, logoutUser } from "../../redux/slices/user";
import { useDispatch, useSelector } from "react-redux";
import { userApi } from "../../apis/UserApi";
import { openSnackbar } from "../../redux/slices/snackbar";
import { production } from "../../configs/settings";

export default function DataProvider() {
  const dispatch = useDispatch();
  const userId = useSelector((state: any) => state.user.userId);

  useEffect(() => {
    if (!userId) return;

    (async () => {
      try {
        const userDetails = await userApi.getUserDetails({ userId: userId });
        if (!userDetails) {
          dispatch(logoutUser());
          return;
        }

        dispatch(
          setUser({
            userId: userDetails._id,
            emailAddress: userDetails.emailAddress,
            verifiedEmail: userDetails.verifiedEmail,
            userName: userDetails.userName,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            externalLinks: userDetails.externalLinks,
            profileImage: userDetails.profileImage,
            stepProgress: userDetails.stepProgress,
          })
        );
      } catch (error: any) {
        if (!production) console.error(error);
        if (error.response && error.response.status === 401) {
          dispatch(logoutUser());
          dispatch(openSnackbar({ message: "Session expired. Please login again", severity: "error" }));
        }
      }
    })();
  }, [userId, dispatch]);

  return null;
}
