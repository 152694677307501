import { Box, Typography } from "@mui/material";

interface BannerHeaderProps {
  bannerDecoration: string;
  bannerIcon: string;
  bannerText: string;
}
function BannerHeader({ bannerDecoration, bannerIcon, bannerText }: BannerHeaderProps) {
  return (
    <Box
      sx={{
        width: "100%",
        height: { xs: "3.5rem", sm: "6rem", md: "5rem" },
        position: "relative",
        color: "#fff",
        fontWeight: "bold",
        textTransform: "uppercase",
        backgroundImage: `linear-gradient(to bottom, #f0a725, #f05725), url(${bannerDecoration})`,
        backgroundBlendMode: "overlay",
        backgroundPosition: "center",
        backgroundSize: { xs: "cover", sm: "auto" },
        backgroundRepeat: "repeat",
        boxShadow: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          position: "absolute",
          top: "50%",
          left: "2%",
          transform: "translateY(-50%)",
        }}
      >
        <Box
          component="img"
          src={bannerIcon}
          alt="Corner Image"
          sx={{
            width: { xs: "3rem", sm: "4rem", md: "5rem" },
            height: { xs: "3rem", sm: "4rem", md: "5rem" },
            objectFit: "contain",
          }}
        />
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3.5rem" },
            lineHeight: { xs: "2rem", sm: "3rem", md: "4rem" },
          }}
        >
          {bannerText}
        </Typography>
      </Box>
    </Box>
  );
}

export default BannerHeader;
