import ReviewCard from "./ReviewCard";
import { Box, useTheme, useMediaQuery, Typography, IconButton } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import profilePicjmeador97 from "../../assets/images/profilepics/profilePicjmeador97.png";
import profilePicAT from "../../assets/images/profilepics/profilePicAT.png";
import profilePicFerderko from "../../assets/images/profilepics/profilePicFerderko.png";
import profilePicRomallSmith from "../../assets/images/profilepics/profilePicRomall.png";
import { useIsMobile } from "../../hooks/useIsMobile";
import DownloadIcon from "@mui/icons-material/Download";
export interface Review {
  id: number;
  name: string;
  discord?: string | null;
  facebook?: string | null;
  rating: number;
  date: string;
  review: string;
  image?: string | null;
}

const reviews: Review[] = [
  {
    id: 1,
    name: "Romall Smith",
    discord: "RomallSmith",
    facebook: null,
    rating: 5,
    date: "March 2024",
    image: profilePicRomallSmith,
    review:
      "The Shock'em team has a different caliber of writer than most. AlphaTrilogy's crew puts their heart into all of their work.",
  },
  {
    id: 2,
    name: "American Texan",
    discord: "American Texan",
    facebook: null,
    rating: 5,
    date: "March 2024",
    image: profilePicAT,

    review:
      "I just read the first comic and it is a banger! If the rest of the lore is gonna be this good, forget DC, I'm gonna be ALL-IN on Shock'em!",
  },
  {
    id: 3,
    name: "ferderko",
    discord: "ferderko",
    facebook: null,
    rating: 5,
    date: "March 2024",
    image: profilePicFerderko,
    review:
      "Just read it! As my first comic ever I thought it was awesome. The art was beautiful. Loved the story, though it was too short. (jk) I didn't catch some of the tech on my first read. After I read the end notes, I went back and re-read it and caught it. Super cool feature, makes comics more accessible for new readers. I really dig the field notes. For me, it gave a more adventurous feel to it and helped me feel more immersed and connected to the story and the characters having a peek into what felt like a personal notebook of Emma. I love the detail. That's always one of my favorite features in video games, so it kind of provided me with some grounding if that makes sense.",
  },
  {
    id: 4,
    name: "American Texan",
    discord: "American Texan",
    facebook: null,
    rating: 5,
    date: "March 2024",
    image: profilePicAT,
    review:
      "Finally read issue 1 and holy cow! Beautifully written. I love the character detail. Didn't notice her bracelet until the guide in the back, now I'm rereading with new insight. Can't wait for number 2.",
  },
  {
    id: 5,
    name: "jmeandor97",
    discord: "jmeandor97",
    facebook: null,
    rating: 5,
    image: profilePicjmeador97,
    date: "March 2024",
    review:
      "I finished reading the story, but I haven't gotten to all the juicy behind the scenes material everyone loves so much. I really love how fun the comic feels. The artwork done by Santiago is absolutely wonderful. He does an amazing job leading the eye through the page. The expressions given to the characters are unique and pull you in as a reader. I am invested in the world you have begun to build, and I couldn't be more proud of the Shock'em Army for putting out such a good product.",
  },
  {
    id: 6,
    name: "ferderko",
    discord: "ferderko",
    facebook: null,
    rating: 5,
    date: "March 2024",
    image: profilePicFerderko,
    review:
      "I really loved everything about it. I'm super excited for the next issue! Loved seeing that the plan is a new issue every 6 weeks and eventually every 2 weeks for the others. The style of storytelling hooked me immediately. It feels authentic and original. Really looking forward to what's next!",
  },
  {
    id: 7,
    name: "Neftaly Orengo",
    discord: null,
    facebook: "Neftaly Orengo",
    rating: 5,
    date: "March 2024",
    review:
      "I've been collecting for 35yrs, and I got to say an indie comics first run,this book is a awesome story. The artwork is gorgeous , the colors are vibrant. Not to mention the pages dedicated to Emma's gear is a great touch. My hats off to the crew at shock'em comics, well done. I'm looking forward to much more.",
  },
];

// Add these props to make it customizable
// interface CustomerReviewsProps {
//   autoScrollInterval?: number;
// }

export default function CustomerReviews() {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));

  const scrollRef = useRef<HTMLDivElement>(null);
  const [isPaused, setIsPaused] = useState(false);

  const cardsToShow = isXs ? 1 : isSm ? 2 : isMd ? 3 : 4;
  const duplicatedReviews = [...reviews];

  // Improved scroll to direction function
  const scrollToDirection = (direction: "left" | "right") => {
    if (!scrollRef.current) return;

    const scrollContainer = scrollRef.current;
    const scrollAmount = scrollContainer.clientWidth;

    setIsPaused(true); // Pause auto-scroll when manually scrolling
    scrollContainer.scrollBy({
      left: direction === "left" ? -scrollAmount / 2 : scrollAmount / 2,
      behavior: "smooth",
    });

    // Resume auto-scroll after animation
    setTimeout(() => setIsPaused(false), 1000);
  };

  // Improved auto scroll effect
  useEffect(() => {
    if (!scrollRef.current) return;

    const scrollContainer = scrollRef.current;

    const autoScroll = () => {
      if (isPaused) return;

      const isAtEnd = scrollContainer.scrollLeft >= scrollContainer.scrollWidth - scrollContainer.clientWidth - 10;

      if (isAtEnd) {
        setIsPaused(true);
        scrollContainer.scrollTo({
          left: 0,
          behavior: "smooth",
        });
        setTimeout(() => {
          setIsPaused(false);
        }, 1000);
      } else {
        scrollContainer.scrollBy({
          left: 1, // Reduced from 4 to 1 for slower scroll
          behavior: "auto",
        });
      }
    };

    // const intervalId = setInterval(autoScroll, 50); // Increased from 20 to 50 for slower scroll

    // return () => clearInterval(intervalId);
  }, [isPaused]);

  // New state to track dragging
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [showInstructions, setShowInstructions] = useState(false);

  // New mouse event handlers for dragging
  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current!.offsetLeft);
    setScrollLeft(scrollRef.current!.scrollLeft);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current!.offsetLeft;
    const walk = (x - startX) * 1.5; // Adjusted multiplier for smoother scroll
    scrollRef.current!.scrollLeft = scrollLeft - walk;
  };

  const handleMouseEnter = () => {
    setShowInstructions(true);
    const timer = setTimeout(() => {
      setShowInstructions(false);
    }, 5000); // Show for 5 seconds
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Box
      sx={{
        mt: "1rem",
        overflow: "hidden",
        width: "100%",
        position: "relative",
      }}
    >
      <Typography sx={{ fontSize: { xs: "1.3rem", sm: "2rem" }, fontWeight: "bold", lineHeight: "1.2", mb: 1 }}>
        What The Shock'em Army Is Saying About Our Comics
      </Typography>

      {showInstructions && (
        <Box
          sx={{
            position: "absolute",
            top: "-0.625rem", // 10px
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            padding: "0.625rem 1.25rem", // 10px 20px
            borderRadius: "0.3125rem", // 5px
            animation: "fadeIn 0.5s, fadeOut 0.5s 4.5s forwards", // Animation for fade in and out
          }}
        >
          To Scroll you can: <br />
          1. Drag using the mouse <br />
          2. Use mouse gestures <br />
          3. Use the buttons on the sides!
        </Box>
      )}

      {/* Left Button */}
      <IconButton
        onClick={() => scrollToDirection("left")}
        sx={{
          position: "absolute",
          left: "-1.5rem", // Half button hidden
          top: "50%",
          transform: "translateY(calc(-50% + 2rem))",
          zIndex: 2,
          width: "4rem", // Doubled size
          height: "4rem", // Doubled size
          backgroundColor: "rgba(255, 255, 255, 0.25)",
          backdropFilter: "blur(8px)",
          border: "1px solid rgba(255, 255, 255, 0.18)",
          boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.15)",
          transition: "left 0.3s ease", // Smooth transition
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.4)",
            left: 0, // Button fully visible on hover
          },
        }}
      >
        <ChevronLeft sx={{ fontSize: "3rem" }} />
      </IconButton>

      {/* Right Button */}
      <IconButton
        onClick={() => scrollToDirection("right")}
        sx={{
          position: "absolute",
          right: "-1.5rem", // Half button hidden
          top: "50%",
          transform: "translateY(calc(-50% + 2rem))",
          zIndex: 2,
          width: "4rem", // Doubled size
          height: "4rem", // Doubled size
          backgroundColor: "rgba(255, 255, 255, 0.25)",
          backdropFilter: "blur(8px)",
          border: "1px solid rgba(255, 255, 255, 0.18)",
          boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.15)",
          transition: "right 0.3s ease", // Smooth transition
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.4)",
            right: 0, // Button fully visible on hover
          },
        }}
      >
        <ChevronRight sx={{ fontSize: "3rem" }} />
      </IconButton>

      <Box
        ref={scrollRef}
        sx={{
          overflowX: "auto",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": { display: "none" },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          cursor: isDragging ? "grabbing" : "grab",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            p: "0.5rem",
            width: "fit-content",
          }}
        >
          {duplicatedReviews.map((review, index) => (
            <Box
              key={`${review.id}-${index}`}
              sx={{
                width: `calc(${100 / cardsToShow}vw - ${(2 * 2) / cardsToShow}rem)`,
                flexShrink: 0,
              }}
            >
              <ReviewCard review={review} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

// Add CSS for animations
const styles = {
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  "@keyframes fadeOut": {
    "0%": { opacity: 1 },
    "100%": { opacity: 0 },
  },
};
