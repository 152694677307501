import { Box, Stack, Typography } from "@mui/material";
import CustomerReviews from "../components/home/CustomerReviews";
import homepageBanner from "../assets/images/homepage_banner.png";
import homepageEmmaAndGabe from "../assets/images/homepage_emma_and_gabe.png";
import homepageWePromise from "../assets/images/we-promise.png";
import { StyledButton } from "../styles/StyledComponents";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import homeBannerMobile from "../assets/images/home-banner-mobile.png";

export default function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const digitalSiteUrl = process.env.REACT_APP_DIGITAL_SITE_URL;

  return (
    <Box>
      {isMobile ? (
        // Mobile
        <React.Fragment>
          <Box
            sx={{
              width: "100%",
              height: "20rem",
              backgroundImage: `url(${homeBannerMobile})`,
              // backgroundSize: "auto 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                boxSizing: "border-box",
                padding: "2rem 1rem",
                width: "100%",
                height: "100%",
                textAlign: "center",
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: { xs: "1.65rem", sm: "3rem" },
                    lineHeight: "1",
                    fontWeight: "bold",
                    textShadow: "0.125rem 0.125rem 0.25rem rgba(0,0,0,0.5)",
                  }}
                >
                  FREE SHOCK'EM COMICS
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: { xs: "1.65rem", sm: "3rem" },
                    lineHeight: "1",
                    fontWeight: "bold",
                    textShadow: "0.125rem 0.125rem 0.25rem rgba(0,0,0,0.5)",
                  }}
                >
                  DIGITAL SUBSCRIPTIONS!
                </Typography>
              </Box>

              <StyledButton
                variant="contained"
                size="large"
                onClick={() => {
                  window.open(digitalSiteUrl, "_blank");
                }}
                sx={{
                  mt: { xs: "1rem", sm: "1.5rem" },
                  fontSize: { xs: "1rem", sm: "1.8rem" },
                  padding: { xs: "0.5rem 1.5rem", sm: "0.2rem 1.5rem" },
                  borderRadius: { xs: "0.5rem", sm: "1rem" },
                }}
              >
                CLAIM NOW!
              </StyledButton>
            </Box>
          </Box>

          <Box
            sx={{
              boxSizing: "border-box",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              p: "1.5rem 1.5rem",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "1rem",
                position: "relative",
              }}
            >
              <Stack sx={{ gap: 1 }}>
                <Typography sx={{ fontSize: { xs: "1.5rem", sm: "2rem" }, fontWeight: "bold" }}>
                  Welcome to Shock'em!
                </Typography>
                <Typography color="text.secondary">
                  At Shock'em Comics, our team is dedicated to creating engaging stories and exceptional art while
                  supporting geek culture. We understand the importance of accessibility and inclusivity, which is why
                  we are thrilled to announce SHOCK'EM. This website now allows us to offer completely free digital
                  subscriptions to our comics, regardless of your financial situation or where you live, everyone can
                  access and enjoy our content!
                </Typography>
              </Stack>
            </Box>
            <Box component={"img"} src={homepageWePromise} sx={{ width: "100%" }} />
            <CustomerReviews />
          </Box>
        </React.Fragment>
      ) : (
        // Desktop
        <React.Fragment>
          <Box
            sx={{
              width: "100%",
              height: "20rem",
              backgroundImage: `url(${homepageBanner})`,
              backgroundSize: "auto 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: { md: "right", lg: "center" },

              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                bottom: "7%",
                left: "4rem",

                maxWidth: "50rem",

                textAlign: "center",
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "0",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: { xs: "2rem", sm: "3rem" },
                    lineHeight: "1",
                    fontWeight: "bold",
                    textShadow: "0.125rem 0.125rem 0.25rem rgba(0,0,0,0.5)",
                  }}
                >
                  FREE SHOCK'EM COMICS
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: { xs: "2rem", sm: "3rem" },
                    lineHeight: "1",
                    fontWeight: "bold",
                    textShadow: "0.125rem 0.125rem 0.25rem rgba(0,0,0,0.5)",
                  }}
                >
                  DIGITAL SUBSCRIPTIONS!
                </Typography>
              </Box>

              <StyledButton
                variant="contained"
                size="large"
                sx={{
                  mt: { xs: "1rem", sm: "1.5rem" },
                  fontSize: { xs: "1rem", sm: "1.8rem" },
                  padding: { xs: "0.5rem 1.5rem", sm: "0.2rem 1.5rem" },
                  borderRadius: { xs: "0.5rem", sm: "1rem" },
                }}
              >
                CLAIM NOW!
              </StyledButton>
            </Box>
          </Box>

          <Box
            sx={{
              boxSizing: "border-box",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              p: "2rem 4rem",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                // justifyContent: "flex-start",
                gap: "1rem",
                position: "relative",
              }}
            >
              <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "1rem" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "0rem" }}>
                  {" "}
                  <Typography sx={{ fontSize: "3rem", fontWeight: "bold", lineHeight: "1.2" }}>
                    Welcome to Shock'em!
                  </Typography>
                  <Typography color="text.secondary" sx={{ fontSize: "1.1rem" }}>
                    At Shock'em Comics, our team is dedicated to creating engaging stories and exceptional art while
                    supporting geek culture. We understand the importance of accessibility and inclusivity, which is why
                    we are thrilled to announce SHOCK'EM. This website now allows us to offer completely free digital
                    subscriptions to our comics, regardless of your financial situation or where you live, everyone can
                    access and enjoy our content!
                  </Typography>
                </Box>
                <Box component={"img"} src={homepageWePromise} sx={{ width: "100%" }} />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  right: "0",
                  bottom: "0",
                  width: "50%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  component={"img"}
                  src={homepageEmmaAndGabe}
                  sx={{
                    height: "140%",
                    maxWidth: "100%",
                    // objectFit: "contain",
                  }}
                />
              </Box>
            </Box>
            <CustomerReviews />
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
