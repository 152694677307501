import React from 'react';
import { Box, Typography, keyframes, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import BoltIcon from '@mui/icons-material/Bolt';

const pulseAnimation = keyframes`
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.1); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
`;

const glowAnimation = keyframes`
  0% { filter: drop-shadow(0 0 2px rgba(255, 215, 0, 0.7)); }
  50% { filter: drop-shadow(0 0 15px rgba(255, 215, 0, 0.9)); }
  100% { filter: drop-shadow(0 0 2px rgba(255, 215, 0, 0.7)); }
`;

const boltAnimation = keyframes`
  0% { transform: translateY(-20px) rotate(0deg); opacity: 0; }
  50% { transform: translateY(0) rotate(10deg); opacity: 1; }
  100% { transform: translateY(20px) rotate(0deg); opacity: 0; }
`;

const LoadingContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.1
  )} 100%)`,
  zIndex: 9999,
}));

const LogoText = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  letterSpacing: '0.2em',
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: 'pulse 2s infinite',
  '@keyframes pulse': {
    '0%': {
      opacity: 0.6,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.6,
    },
  },
}));

const StyledBolt = styled(BoltIcon)(({ theme }) => ({
  fontSize: '3rem',
  color: theme.palette.primary.main,
  filter: `drop-shadow(0 0 10px ${alpha(theme.palette.primary.main, 0.5)})`,
  animation: 'bounce-rotate 1.5s infinite',
  '@keyframes bounce-rotate': {
    '0%, 100%': {
      transform: 'translateY(0) rotate(0deg)',
    },
    '50%': {
      transform: 'translateY(-20px) rotate(10deg)',
    },
  },
}));

export default function LoadingFallback() {
  return (
    <LoadingContainer>
      <LogoText variant="h1">
        Shockem
      </LogoText>
      <StyledBolt />
      <Typography
        sx={{
          mt: 2,
          color: '#666',
          fontSize: '1rem',
          fontWeight: 500,
          opacity: 0.8,
        }}
      >
        Loading amazing content...
      </Typography>
    </LoadingContainer>
  );
}
