import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { green, red } from "@mui/material/colors";

interface PasswordRequirementProps {
  label: string;
  valid: boolean;
}

export default function PasswordRequirement({ label, valid }: PasswordRequirementProps) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {valid ? (
        <CheckCircleIcon sx={{ color: green[500], fontSize: "1rem" }} />
      ) : (
        <RadioButtonUncheckedIcon sx={{ color: red[500], fontSize: "1rem" }} />
      )}
      <Typography variant="body2" sx={{ ml: 1 }}>
        {label}
      </Typography>
    </Box>
  );
}
