import { scratchCardTypeEnum } from "../types/scratchCardEnum";
import BaseAPI from "./BaseApi";

interface ExternalLinksType {
  instagram?: string;
  email?: string;
  twitter?: string;
  discord?: string;
  website?: string;
}

export interface ShockemUserType {
  _id: string;
  emailAddress: string;
  verifiedEmail: boolean;
  userName?: string;
  profileImage?: string; // Assuming it's an object with unknown properties
  coverImage?: string;
  interests?: string[];
  password?: string;
  externalLinks?: ExternalLinksType;
  description?: string;
  stepProgress?: any;
  accountCreatedAt?: Date;
  lastActiveAt?: Date;
  lastLoggedInAt?: Date;
  referralsLeft?: number;
}
class UserApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/user", cache: false });
  }

  async getUserDetails({ userId, email }: { userId?: string; email?: string }): Promise<any> {
    try {
      if (!userId && !email) {
        throw new Error("userId or email is required");
      }

      const result = await this.axios.get(``, {
        params: {
          userId,
          email,
        },
      });
      if (!result.data || !result.data?.success) throw new Error("Invalid response from server");
      return result.data.userDetails;
    } catch (error: any) {
      throw new Error("Error in UserApi.getUserDetails: " + error.message);
    }
  }

  async getPaginatedAllUsers({
    userId,
    limit,
    page,
    project,
    sortKey,
    sortDirection,
    total = false,
  }: {
    userId?: string;
    limit?: number;
    page?: number;
    project?: any;
    sortKey?: string;
    sortDirection?: string;
    total?: boolean;
  }): Promise<any> {
    try {
      const result = await this.axios.get("/getAllUsers", {
        params: {
          userId,
          limit,
          page,
          project,
          sortKey,
          sortDirection,
          total,
        },
      });
      if (!result.data || !result.data?.success || !result.data?.usersData)
        throw new Error("Invalid response from server");
      return result.data.usersData;
    } catch (error: any) {
      throw new Error("Error in UserApi.getPaginatedAllUsers: " + error.message);
    }
  }
  async getPaginatedUsersByEvent({
    eventField,
    timestamp,
    limit,
    page,
    project,
    sortKey,
    sortDirection,
    total = false,
  }: {
    eventField: string;
    timestamp: string;
    limit?: number;
    page?: number;
    project?: any;
    sortKey?: string;
    sortDirection?: string;
    total?: boolean;
  }): Promise<any> {
    try {
      const result = await this.axios.get("/getUsersByEvent", {
        params: {
          eventField,
          timestamp,
          limit,
          page,
          project,
          sortKey,
          sortDirection,
          total,
        },
      });
      if (!result.data || !result.data?.success || !result.data?.usersData)
        throw new Error("Invalid response from server");
      return result.data.usersData;
    } catch (error: any) {
      throw new Error("Error in UserApi.getPaginatedUsersByEvent: " + error.message);
    }
  }

  async updateLastActive({ userId }: { userId: string }): Promise<any> {
    try {
      const result = await this.axios.post(`/last-active/${userId}`);
      if (!result.data || !result.data?.success) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in UserApi.updateLastActive: " + error.message);
    }
  }

  async updateUserDetails(formData: any): Promise<any> {
    try {
      const result = await this.axios.put("/update", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if ((!result.data || !result.data?.success) && result.data?.code === "DUPLICATE_ERROR") {
        throw new Error(result.data?.code);
      }
      if (!result.data || !result.data?.success) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      if (error?.response?.data?.code === "DUPLICATE_ERROR") {
        throw new Error(error?.response?.data?.code);
      }
      throw new Error("Error in UserApi.updateUserDetails: " + error.message);
    }
  }

  async sendReferalEmail({
    referralEmail,
    referralName,
    userEmail,
    customMessage,
    resend = false,
    newEmail,
    referralType,
  }: {
    referralEmail: string;
    referralName: string;
    userEmail: string;
    customMessage: string;
    resend?: Boolean;
    newEmail?: string;
    referralType: scratchCardTypeEnum.PhysicalReferral | scratchCardTypeEnum.PromoReferral;
  }): Promise<any> {
    try {
      const result = await this.axios.post("/sendReferral", {
        referralEmail,
        referralName,
        userEmail,
        customMessage,
        resend,
        newEmail,
        referralType,
      });
      if (!result.data || !result.data?.success) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || `Failed to send referral email. ${error.message || error}`;
      throw new Error(errorMessage);
    }
  }

  async getReferrals({ userEmail }: { userEmail: string }): Promise<any> {
    try {
      if (!userEmail) {
        return [];
      }
      const result = await this.axios.get("/getReferrals", { params: { userEmail } });
      if (!result.data || !result.data?.success) throw new Error("Invalid response from server");
      return result.data.referrals;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || `Failed to send referral email. ${error.message || error}`;
      throw new Error(errorMessage);
    }
  }
}

export const userApi = new UserApi();
