import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import EditProfileEmail from "../components/common/EditProfileEmail";
import EditProfilePassword from "../components/common/EditProfilePassword";
import { setUser } from "../redux/slices/user";
import { Settings } from "@mui/icons-material";

export default function AccountSettings() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);

  const [showEmailDialog, setShowEmailDialog] = useState(false);

  const updateUserData = (updatedUser: any) => {
    if (updatedUser?.emailAddress) {
      dispatch(setUser(updatedUser));
      setShowEmailDialog(false);
    }
  };

  return (
    <Box sx={{ p: 3, maxWidth: 900, mx: "auto" }}>
      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: 3,
          backgroundColor: "background.paper",
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: 4,
            fontWeight: 600,
            color: "primary.main",
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Settings fontSize="large" />
          Account Settings
        </Typography>

        <Grid container spacing={4}>
          {/* First Row */}
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              fullWidth
              label="First Name"
              value={user?.firstName || ""}
              disabled
              InputProps={{
                sx: {
                  backgroundColor: "action.hover",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                },
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              fullWidth
              label="Last Name"
              value={user?.lastName || ""}
              disabled
              InputProps={{
                sx: {
                  backgroundColor: "action.hover",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                },
              }}
            />
          </Grid>

          {/* Second Row */}
          <Grid size={{ xs: 12, sm: 4 }}>
            <TextField
              fullWidth
              label="Username"
              value={user?.userName || ""}
              disabled
              InputProps={{
                sx: {
                  backgroundColor: "action.hover",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                },
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 8 }}>
            <Paper
              elevation={0}
              sx={{
                p: 1.5,
                backgroundColor: theme.palette.background.default,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 2,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "flex-start", sm: "center" },
                  gap: 2,
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    flex: 1,
                    width: "100%",
                    minWidth: 0,
                  }}
                >
                  <EmailIcon sx={{ color: "action.active", flexShrink: 0 }} />
                  <Box
                    sx={{
                      overflow: "hidden",
                      flex: 1,
                      minWidth: 0,
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="div"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "auto",
                        width: "100%",
                        display: "block",
                        "&::-webkit-scrollbar": {
                          height: "0.1rem",
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "background.paper",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "grey.300",
                          borderRadius: "1rem",
                        },
                      }}
                    >
                      {user?.emailAddress}
                    </Typography>
                  </Box>
                  {user?.verifiedEmail ? (
                    <Tooltip title="Email verified">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                          backgroundColor: "success.main",
                          color: "success.contrastText",
                          borderRadius: "1rem",
                          px: 1,
                          py: 0.5,
                          fontSize: "0.75rem",
                          fontWeight: 500,
                        }}
                      >
                        <VerifiedUserIcon sx={{ fontSize: "1rem" }} />
                      </Box>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Email not verified">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                          backgroundColor: "warning.light",
                          color: "warning.contrastText",
                          borderRadius: "1rem",
                          px: 1,
                          py: 0.5,
                          fontSize: "0.75rem",
                          fontWeight: 500,
                        }}
                      >
                        <ErrorOutlineIcon sx={{ fontSize: "1rem" }} />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    flexShrink: 0,
                    width: { xs: "100%", sm: "auto" },
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      fontSize: { xs: "0.8rem", sm: "0.8rem" },
                      flex: { xs: 1, sm: "none" },
                    }}
                    onClick={() => setShowEmailDialog(true)}
                  >
                    Change Email
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* Password Row */}
          <Grid size={{ xs: 12 }}>
            <EditProfilePassword />
          </Grid>
        </Grid>
      </Paper>

      <Dialog open={showEmailDialog} onClose={() => setShowEmailDialog(false)} fullWidth>
        <EditProfileEmail
          userDetails={user}
          onClose={() => setShowEmailDialog(false)}
          updateUserData={updateUserData}
        />
      </Dialog>
    </Box>
  );
}
