import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import store from "./redux/store";
import { ThemeProvider } from "@mui/material";
import { lightTheme } from "./configs/muiTheme";
import SnackbarProvider from "./components/provider/SnackbarProvider";
import DataProvider from "./components/provider/DataProvider";
import CommonLayoutProvider from "./components/provider/CommonLayoutProvider";
import { lazy, Suspense, memo } from "react";
import LoadingFallback from "./components/common/LoadingFallback";
import { useEffect } from "react";
import addTrackJSScript from "./addTrackJSScript";
import Home from "./pages/Home";
import Login from "./pages/Login";
import ContactUs from "./pages/ContactUs";
import AccountSettings from "./pages/AccountSettings";
import Signup from "./pages/Signup";
import { production } from "./configs/settings";

// Lazy load all pages
const User = lazy(() => import("./pages/User"));
const Item = lazy(() => import("./pages/Item"));
const VerifyEmail = lazy(() => import("./pages/VerifyEmail"));
const RedeemCouponRedirect = lazy(() => import("./pages/RedeemCouponRedirect"));
const RedeemCoupon = lazy(() => import("./pages/RedeemCoupon"));
const ReferUserPage = lazy(() => import("./pages/ReferUserPage"));
const CollectionLeaderBoard = lazy(() => import("./pages/CollectionLeaderBoard"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const FlippingBookEmbed = lazy(() => import("./pages/FlippingBookEmbed"));
// const AccountSettings = lazy(() => import("./pages/AccountSettings"));
// const ContactUs = lazy(() => import("./pages/ContactUs"));
const NotFound = lazy(() => import("./pages/NotFound"));

// Memoized CommonLayout wrapper
const RouteWrapper = memo(
  ({
    Component,
    options = {},
    componentProps = {},
  }: {
    Component: React.ComponentType<any>;
    options?: Partial<{
      authBasedRedirect: boolean;
      showFooter: boolean;
      showFooterSocialIcons: boolean;
    }>;
    componentProps?: Record<string, any>;
  }) => (
    <Suspense fallback={<LoadingFallback />}>
      <CommonLayoutProvider {...options}>
        <Component {...componentProps} />
      </CommonLayoutProvider>
    </Suspense>
  )
);

// Helper function to create route with CommonLayout
const createRoute = (
  path: string,
  Component: React.ComponentType<any>,
  options: Partial<{
    authBasedRedirect: boolean;
    showFooter: boolean;
    showFooterSocialIcons: boolean;
  }> = {},
  componentProps: Record<string, any> = {}
) => ({
  path,
  element: <RouteWrapper Component={Component} options={options} componentProps={componentProps} />,
});

// Define routes
const routes = [
  // Main pages
  createRoute("/", Home, { authBasedRedirect: false }, { initialTab: "featured" }),
  createRoute("/user/:userId", User, { authBasedRedirect: false }, { showProfile: true }),
  createRoute("/user", User),

  // Authentication related
  createRoute("/login", Login, { showFooterSocialIcons: false }),
  createRoute(
    "/signup",
    Signup,
    { showFooterSocialIcons: false }
    // { scratchCardDetails: { type: scratchCardTypeEnum.PromoCode, fullCode: "PHYSICAL" } }
  ),
  createRoute("/verify-email", VerifyEmail),
  createRoute("/reset-password", ResetPassword, { authBasedRedirect: false }),

  // Collection related
  createRoute("/item/:collectionId/:sequenceNumber", Item),
  createRoute("/leaderBoard", CollectionLeaderBoard),

  // Rewards and referrals
  createRoute("/reward-a-friend", ReferUserPage),
  createRoute("/redeem-scratch-card-redirect/:token", RedeemCouponRedirect, { authBasedRedirect: false }),
  createRoute("/redeem-scratch-card/:couponCode", RedeemCoupon, { showFooterSocialIcons: false }),

  // User settings
  createRoute("/account-settings", AccountSettings),

  // Other pages
  createRoute("/contact-us", ContactUs, { authBasedRedirect: false }),
  createRoute("/flipping-book", FlippingBookEmbed, { authBasedRedirect: false }),
  createRoute("*", NotFound, { authBasedRedirect: false }),
];

// Memoize the router creation
const router = createBrowserRouter(routes);

if (!production) console.error("This is not production");
else console.log("This is production");

// Memoized App component
const App = memo(() => {
  useEffect(() => {
    if (process.env.REACT_APP_PRODUCTION === "true") {
      // Run TrackJS script only in production
      addTrackJSScript();
    }
  }, []);

  return (
    <Provider store={store}>
      <DataProvider />
      <ThemeProvider theme={lightTheme}>
        <SnackbarProvider>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
});

export default App;
