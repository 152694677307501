import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { userApi } from "../../apis/UserApi";
import { openSnackbar } from "../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { loginApi } from "../../apis/LoginApi";
import { validateEmail } from "../../utils/validation";
import { production } from "../../configs/settings";

interface EditEmailProps {
  userDetails: any;
  updateUserData?: any;
  onClose: any;
}

export default function EditProfileEmail({ userDetails, updateUserData, onClose }: EditEmailProps) {
  const dispatch = useDispatch();
  const [otpEmailSent, setOtpEmailSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [emailError, setEmailError] = useState<string>("");
  const [isResending, setIsResending] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (cooldownTime > 0) {
      timer = setInterval(() => {
        setCooldownTime((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [cooldownTime]);

  async function handleResendOTP() {
    try {
      setIsResending(true);
      const res = await loginApi.generateOtpAndSendEmail({ email: emailAddress });
      if (!res?.success) throw new Error("Error in sending email");
      dispatch(openSnackbar({ message: "OTP resent successfully", severity: "success" }));
      setCooldownTime(30); // 30 seconds cooldown
    } catch (error) {
      if (!production) console.error(error);
      dispatch(openSnackbar({ message: "Error in resending OTP", severity: "error" }));
    } finally {
      setIsResending(false);
    }
  }

  async function handleSave() {
    try {
      if (!otpEmailSent) {
        if (!validateEmail(emailAddress)) {
          setEmailError("Please enter a valid email address");
          return;
        }
        if (emailAddress === userDetails.emailAddress) {
          setEmailError("Email address cannot be same as current email address");
          return;
        }
        setEmailError("");

        const res = await loginApi.generateOtpAndSendEmail({ email: emailAddress });
        if (!res?.success) throw new Error("Error in sending email");
        dispatch(openSnackbar({ message: "OTP sent successfully", severity: "success" }));
        setOtpEmailSent(true);
        setCooldownTime(30); // Set initial cooldown after first OTP send
      } else if (!otpVerified) {
        const res = await loginApi.verifyOtp(emailAddress, otp);
        if (!res?.success) throw new Error("Invalid verification code");
        const updatedProfile = { emailAddress };
        const filter = { _id: userDetails.userId };
        const formData = new FormData();
        formData.append("filter", JSON.stringify(filter));
        formData.append("userObjUpdates", JSON.stringify(updatedProfile));
        const updateRes = await userApi.updateUserDetails(formData);
        if (!updateRes?.success) throw new Error("Error in updating profile");
        setOtpVerified(true);
        if (updateUserData) updateUserData(updateRes?.user);
        dispatch(openSnackbar({ message: "User Email updated successfully", severity: "success" }));
      }
    } catch (error: any) {
      if (!production) console.error(error);
      if (error.message === "DUPLICATE_ERROR") {
        setEmailError("This email address is already in use");
        setOtpEmailSent(false);
        return;
      }
      dispatch(openSnackbar({ message: "Error in updating email", severity: "error" }));
    }
  }

  return (
    <Box sx={{ zIndex: 2 }}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <IconButton edge="start" color="inherit" onClick={() => onClose()} aria-label="close">
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "left" }}>
          Edit Email
        </Typography>
        <Typography variant="body2" sx={{ color: "orange", fontWeight: "bold", fontSize: "1rem" }}>
          {userDetails?.emailAddress}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack gap={4} sx={{ position: "relative", p: 2 }}>
          <TextField
            fullWidth
            label="New Email Address"
            value={emailAddress}
            onChange={(e) => {
              setEmailAddress(e.target.value);
              setEmailError("");
            }}
            disabled={otpEmailSent}
            error={!!emailError}
            helperText={emailError}
          />
          {otpEmailSent && (
            <Box>
              <TextField
                fullWidth
                label="OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                disabled={otpVerified}
              />
              {!otpVerified && (
                <Button onClick={handleResendOTP} disabled={isResending || cooldownTime > 0} sx={{ mt: 1 }}>
                  {isResending ? "Resending..." : cooldownTime > 0 ? `Resend OTP (${cooldownTime}s)` : "Resend OTP"}
                </Button>
              )}
            </Box>
          )}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {(!otpEmailSent || !otpVerified) && (
          <Button onClick={handleSave} color="primary">
            {!otpEmailSent ? "Send OTP" : !otpVerified ? "Verify" : ""}
          </Button>
        )}
      </DialogActions>
    </Box>
  );
}
