import React, { useState } from "react";
import { Box, Button, Paper, TextField, Typography, InputAdornment, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import LockIcon from "@mui/icons-material/Lock";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import { userApi } from "../../apis/UserApi";
import { loginApi } from "../../apis/LoginApi";
import { validatePassword } from "../../utils/validation";
import PasswordRequirement from "../signup/PasswordRequirement";
import { production } from "../../configs/settings";

function EditProfilePassword() {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState("");

  const passwordRequirements = validatePassword(password, confirmPassword).passwordRequirements;

  async function handleSavePassword() {
    setShowErrors(true);
    try {
      if (!currentPassword) {
        dispatch(openSnackbar({ message: "Please enter your current password", severity: "error" }));
        return;
      }

      if (password !== confirmPassword) {
        dispatch(openSnackbar({ message: "Passwords do not match", severity: "error" }));
        return;
      }

      // First verify current credentials
      try {
        await loginApi.verifyCredentials(user.emailAddress, currentPassword);
      } catch (error: any) {
        if (error.message === "INVALID_CREDENTIALS") {
          dispatch(openSnackbar({ message: "Current password is incorrect", severity: "error" }));
          setShowErrors(true);
          setCurrentPasswordError("Invalid current password");
          return;
        }
        throw error;
      }

      // If verification successful, update password
      const formData = new FormData();
      const filter = { _id: user.userId };
      const updatedProfile = { password: password };
      formData.append("filter", JSON.stringify(filter));
      formData.append("userObjUpdates", JSON.stringify(updatedProfile));

      const updateRes = await userApi.updateUserDetails(formData);
      if (!updateRes?.success) throw new Error(updateRes?.message || "Error updating password");

      dispatch(openSnackbar({ message: "Password updated successfully", severity: "success" }));
      setIsEditingPassword(false);
      setCurrentPassword("");
      setPassword("");
      setConfirmPassword("");
      setShowErrors(false);
    } catch (error: any) {
      if (!production) console.error(error);
      dispatch(openSnackbar({ message: error.message || "Failed to update password", severity: "error" }));
    }
  }

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        backgroundColor: isEditingPassword ? "background.paper" : "action.hover",
        border: "1px solid",
        borderColor: isEditingPassword ? "primary.main" : "transparent",
        borderRadius: 2,
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: isEditingPassword ? 2 : 0 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {!isEditingPassword && <LockIcon color="action" />}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h6">{!isEditingPassword ? "Password Settings" : "Set New Password"}</Typography>
            {isEditingPassword && (
              <Typography variant="body2" color="text.secondary">
                Change your password
              </Typography>
            )}
          </Box>
        </Box>
        {!isEditingPassword && (
          <Button
            variant={isEditingPassword ? "contained" : "outlined"}
            onClick={() => (isEditingPassword ? handleSavePassword() : setIsEditingPassword(true))}
          >
            Change Password
          </Button>
        )}
      </Box>

      {isEditingPassword && (
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 12 }}>
            <TextField
              fullWidth
              label="Current Password"
              type={showCurrentPassword ? "text" : "password"}
              value={currentPassword}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
                setCurrentPasswordError("");
              }}
              error={showErrors && currentPasswordError.length > 0}
              helperText={showErrors && currentPasswordError ? currentPasswordError : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle current password visibility"
                      onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                      edge="end"
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 12 }}>
            <TextField
              fullWidth
              type={showNewPassword ? "text" : "password"}
              label="New Password"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              error={password.length > 0 && (!passwordRequirements.length || !passwordRequirements.number)}
              helperText={
                password.length > 0 && (!passwordRequirements.length || !passwordRequirements.number)
                  ? "Password must be at least 8 characters long and contain a number"
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle new password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 12 }}>
            <TextField
              fullWidth
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={(e: any) => setConfirmPassword(e.target.value)}
              error={confirmPassword.length > 0 && password !== confirmPassword}
              helperText={confirmPassword.length > 0 && password !== confirmPassword ? "Passwords do not match" : ""}
            />
          </Grid>
          <Grid
            size={{ xs: 12, sm: 12 }}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: { xs: "column", sm: "row" },
              gap: "1rem",
            }}
          >
            <PasswordRequirement label={"At least 8 characters"} valid={passwordRequirements.length} />
            <PasswordRequirement label={"At least one number"} valid={passwordRequirements.number} />
            <PasswordRequirement
              label={"Passwords match"}
              valid={passwordRequirements.length && password === confirmPassword}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }} sx={{ display: "flex", justifyContent: "flex-start", gap: "0.5rem" }}>
            <Button
              variant={isEditingPassword ? "contained" : "outlined"}
              onClick={() => (isEditingPassword ? handleSavePassword() : setIsEditingPassword(true))}
              startIcon={isEditingPassword ? <SaveIcon /> : <EditIcon />}
              disabled={!validatePassword(password, confirmPassword).isPasswordValid || !currentPassword}
            >
              Save
            </Button>
            <Button variant={"outlined"} color="secondary" onClick={() => setIsEditingPassword(false)}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

export default EditProfilePassword;
