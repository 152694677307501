import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { scratchCardApi } from "../../apis/ScratchCardApi";
import subscriptionsTextImage from "../../assets/images/subscriptions-text.png"; // Add this import
import emmaAndGabeSubscriptionCountImage from "../../assets/images/emma_and_gabe_subscription_count.png"; // Add this import
import { production } from "../../configs/settings";

export const SubscriptionsGiveAway = () => {
  const [promoCodeCount, setPromoCodeCount] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const count = await scratchCardApi.getUserScratchCardsCount({ type: "promo-code" });
        setPromoCodeCount(count);
      } catch (error) {
        if (!production) console.error("Failed to fetch promo code count:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        ...(promoCodeCount < 1000
          ? {
              paddingTop: "2rem",
            }
          : {
              paddingTop: "1rem",
            }),
        alignItems: "center",
        gap: "1.5rem",
      }}
    >
      {promoCodeCount >= 1000 && (
        <>
          {/* Title */}
          <Typography color="primary.main" textAlign="center" sx={{ fontSize: "2rem", color: "white" }}>
            WE HAVE GIVEN AWAY
          </Typography>

          {/* Count Boxes with 'S OF' suffix */}
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {"1000'SOF"
              .toString()
              .split("")
              .map((digit, index) => (
                <Box
                  key={index}
                  sx={{
                    opacity: digit === " " ? 0 : 1,
                    width: "auto",
                    height: "auto",
                    backgroundColor: "black",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "0.25rem",
                    boxShadow: "0 0.125rem 0.25rem rgba(0,0,0,0.2)",
                    padding: "0.6rem 0.2rem",
                    minWidth: "2rem",
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      width: "100%",
                      height: "0.2rem",
                      backgroundColor: "black",
                      top: "50%",
                      transform: "translateY(-50%)",
                    },
                  }}
                >
                  <Typography fontWeight="bold" color="white" sx={{ fontSize: "3rem", lineHeight: 1 }}>
                    {digit}
                  </Typography>
                </Box>
              ))}
          </Box>

          {/* Subscription Text Image */}
          <Box
            component="img"
            src={subscriptionsTextImage}
            alt="Subscriptions"
            sx={{
              width: "90%",
              height: "4.5rem",
            }}
          />
        </>
      )}

      {/* Add Emma and Gabe Image */}
      <Box
        component="img"
        src={emmaAndGabeSubscriptionCountImage}
        alt="Emma and Gabe Subscription Count"
        sx={{
          marginTop: "auto", // This will push the image to the bottom
          width: "90%",
          height: "auto",
          maskImage: "linear-gradient(to bottom, black 85%, transparent 100%)",
          WebkitMaskImage: "linear-gradient(to bottom, black 85%, transparent 100%)",
        }}
      />
    </Box>
  );
};
