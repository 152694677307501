import BaseAPI from "./BaseApi";

class LoginApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/userAuth", cache: false });
  }

  async verifyCredentials(email: string, password: string) {
    try {
      const result = await this.axios.post("/verifyCredentials", {
        email,
        password,
      });
      if (!result.data || !result.data.user) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        throw new Error("INVALID_CREDENTIALS");
      }
      if (error?.response?.status === 404) {
        throw new Error("INVALID_CREDENTIALS");
      }
      if (error?.response?.status === 403) {
        throw new Error("UNAUTHORIZED");
      }
      throw new Error("Error in LoginApi.verifyCredentials: " + error.message);
    }
  }

  async generateOtpAndSendEmail({ email, phoneNumber }: { email?: string; phoneNumber?: string }) {
    try {
      if (!email && !phoneNumber) {
        throw new Error("At least one of phoneNumber and email required");
      }

      const result = await this.axios.post("/generateOtpAndSendEmail", {
        email,
        phoneNumber,
      });
      if (!result.data || !result.data?.success) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in LoginApi.generateOtpAndSendEmail: " + error.message);
    }
  }

  async verifyOtp(email: string, otp: string) {
    try {
      const result = await this.axios.get("/verifyOtp", {
        params: {
          email,
          otp,
        },
      });
      if (!result.data) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in LoginApi.verifyOtp: " + error.message);
    }
  }

  /**
   * @param {string} input - email or username
   * @param {string} password
   */
  async signInUser(input: string, password: string) {
    try {
      const result = await this.axios.post("/login", {
        input,
        password,
      });
      if (!result.data) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in LoginApi.signInUser: " + error.message);
    }
  }

  async sendEmailForSignupVerification({ userId }: { userId: string }) {
    try {
      const result = await this.axios.get("/sendEmailForSignupVerification", {
        params: {
          userId,
        },
      });
      if (!result.data || !result.data?.success) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in LoginApi.sendEmailForSignupVerification: " + error.message);
    }
  }

  async verifyEmailApi({ token }: { token: string }) {
    try {
      const result = await this.axios.post("/verifyEmail", {
        token,
      });

      if (!result.data || !result.data?.success) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in LoginApi.verifyEmail: " + error.message);
    }
  }

  async decodeToken({ token }: { token: string }) {
    try {
      const result = await this.axios.post("/decodeToken", {
        token,
      });

      if (!result.data || !result.data?.success || !result.data?.decodedTokenInfo)
        throw new Error("Invalid response from server");
      return result.data.decodedTokenInfo;
    } catch (error: any) {
      if (error?.response?.data?.message === "Token expired") {
        throw new Error("Token expired");
      }

      throw new Error("Error in LoginApi.decodeToken: " + error.message);
    }
  }

  async sendEmailForForgotPassword({ email }: { email: string }) {
    try {
      const result = await this.axios.post("/sendEmailForForgotPassword", {
        email,
      });
      if (!result.data || !result.data?.success) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in LoginApi.sendEmailForForgotPassword: " + error.message);
    }
  }

  async changePassword({ token, password }: { token: string; password: string }) {
    try {
      const result = await this.axios.post("/changePassword", {
        token,
        newPassword: password,
      });
      if (!result.data || !result.data?.success) throw new Error("Invalid response from server");
      return result;
    } catch (error: any) {
      throw new Error("Error in LoginApi.changePassword: " + error.message);
    }
  }
}

export const loginApi = new LoginApi();
