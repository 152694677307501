import { useCallback, useEffect, useRef, useState } from "react";
import { AppBar, Avatar, Box, Button, IconButton, Popover } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Login, Logout } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { userApi } from "../../apis/UserApi";
import { useDispatch, useSelector } from "react-redux";
import { getAuthCookies } from "../../utils/auth";
import ShockemMarketLogo from "../../assets/images/shockem-logo.png";
import { logoutUser } from "../../redux/slices/user";
import { useAuthBasedRedirect } from "../../hooks/useAuthBasedRedirect";
import { getImageUrlFromProfileImageField } from "../../utils/user";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import * as React from "react";
import { production } from "../../configs/settings";

export const HEADER_HEIGHT = {
  xs: "3.2rem",
  sm: "4.5rem",
};

export default function Header({ authBasedRedirect = true }: { authBasedRedirect?: boolean }) {
  useAuthBasedRedirect({ authBasedRedirect });
  const location = useLocation();
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [imageFailed, setImageFailed] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const profileButtonRef = useRef<HTMLButtonElement>(null);
  const moreButtonRef = useRef<HTMLButtonElement>(null);
  const user = useSelector((state: any) => state.user) || getAuthCookies();
  const dispatch = useDispatch();

  function handleLogout() {
    handleCloseMenu();
    handleCloseMore();
    dispatch(logoutUser());
  }

  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const target = event.currentTarget;
    setAnchorEl(target);
    setShowMore(true);
  };

  const handleCloseMore = () => {
    setShowMore(false);
    setAnchorEl(null);
  };

  const handleCloseMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  const handleOpenMenu = useCallback(() => {
    setShowMenu(true);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!user || !user?.userId) return;
      userApi.updateLastActive({ userId: user?.userId }).catch(() => {
        if (!production) console.error("Error in updating last active");
      });
    }, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [user]);

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "rgba(255,255,255,0.6)",
          backdropFilter: "blur(1rem)",
          boxShadow: "3rem",
          height: { xs: HEADER_HEIGHT.xs, sm: HEADER_HEIGHT.sm },
          width: "100vw",
          left: 0,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            height: "100%",
            padding: { xs: "0.5rem 0rem 0.5rem 0rem", sm: "1rem 0rem 1rem 1rem" },
            boxSizing: "border-box",
          }}
        >
          <IconButton onClick={handleOpenMenu} color="primary" aria-label="open-menu">
            <MenuIcon sx={{ fontSize: "2.5rem" }} />
          </IconButton>

          <Box
            display="flex"
            alignItems="center"
            aria-label="return-home"
            sx={{
              justifyContent: "center",
              flexGrow: 1,
              m: 0, // margin: 0 on all sides
              p: 0, // padding: 0 on all sides
              lineHeight: 0, // Remove any line-height spacing
            }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              component={"img"}
              src={ShockemMarketLogo}
              sx={{
                height: { sm: "2.5rem", xs: "2rem" },
                display: "block", // Removes any image spacing
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            />
          </Box>

          {user.userId ? (
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={() => navigate("/user")}
                color="primary"
                ref={profileButtonRef}
                aria-label="open-user-profile"
                sx={{ p: { xs: 0, sm: 1 } }}
              >
                {!imageFailed ? (
                  <Box
                    component={"img"}
                    src={getImageUrlFromProfileImageField(user.profileImage)}
                    sx={{ width: "2.5rem", aspectRatio: "1/1", borderRadius: "50%" }}
                    onError={() => setImageFailed(true)}
                  />
                ) : (
                  <Avatar sx={{ color: "primary" }} />
                )}
              </IconButton>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={handleProfileClick}
                  ref={moreButtonRef}
                  color="primary"
                  aria-label="open-user-profile"
                  sx={{ pl: 0 }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box width="3.5rem" />
          )}
        </Box>
      </AppBar>

      {
        <>
          {/* Dialogs */}
          <Menu open={showMenu} onClose={handleCloseMenu} />
          <Popover
            open={showMore}
            onClose={handleCloseMore}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              "& .MuiPopover-paper": {
                mt: 1,
                width: "auto",
                maxWidth: "none",
              },
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="left" gap="0.5rem">
              {/* <Button variant="outlined" startIcon={<Settings />}>
              Setting
            </Button> */}
              {!(location.pathname === "/login" || location.pathname === "/signup") ? (
                <Button variant="outlined" onClick={handleLogout} startIcon={<Logout />}>
                  Logout
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate("/login");
                      handleCloseMore();
                    }}
                    startIcon={<Login />}
                  >
                    SIGN IN
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate("/signup");
                      handleCloseMore();
                    }}
                    startIcon={<SpaceDashboardIcon />}
                  >
                    SIGN UP
                  </Button>
                </>
              )}
            </Box>
          </Popover>
        </>
      }
    </Box>
  );
}
