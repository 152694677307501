import { Button, styled } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: "#03bf62",
  color: "white",
  padding: "0.25rem 1rem",
  fontWeight: "bold",
  fontSize: "1.5rem",
  whiteSpace: "nowrap",
  borderRadius: "0.5rem",
  textShadow: "0 0.06rem 0 black",
  "&:hover": {
    backgroundColor: "#1f9c61",
  },
}));
