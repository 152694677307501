import { Facebook, Instagram, X, YouTube } from "@mui/icons-material";
import { FaTiktok } from "react-icons/fa";
import { Stack } from "@mui/material";
import React from "react";
import { FaDiscord } from "react-icons/fa";

export default function SocialIcon() {
  return (
    <Stack direction={"row"} sx={{ fontSize: "1.3rem", justifyContent: "center" }} gap={2}>
      <FaDiscord
        fontSize={"inherit"}
        style={{ cursor: "pointer" }}
        onClick={() => window.open("https://discord.com/invite/shockemcomics", "_blank")}
      />
      <Instagram
        fontSize={"inherit"}
        sx={{ cursor: "pointer" }}
        onClick={() => window.open("https://www.instagram.com/shockemcomics/", "_blank")}
      />
      <X
        fontSize={"inherit"}
        sx={{ cursor: "pointer" }}
        onClick={() => window.open("https://x.com/shockem_comics?lang=en", "_blank")}
      />
      <FaTiktok
        fontSize={"inherit"}
        style={{ cursor: "pointer" }}
        onClick={() => window.open("https://www.tiktok.com/@shockemcomics", "_blank")}
      />
      <Facebook
        fontSize={"inherit"}
        sx={{ cursor: "pointer" }}
        onClick={() => window.open("https://www.facebook.com/people/Shockem-Comics/61560347762061/", "_blank")}
      />
      <YouTube
        fontSize={"inherit"}
        sx={{ cursor: "pointer" }}
        onClick={() => window.open("https://www.youtube.com/@shockemcomics", "_blank")}
      />
    </Stack>
  );
}
