import { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Box,
  Paper,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import WelcomeSection from "../components/common/WelcomeSection";
import { validateEmail, validatePassword, validateName } from "../utils/validation";
import PasswordRequirement from "../components/signup/PasswordRequirement";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import { signupApi } from "../apis/SignupApi";
import EmailSentMessage from "../components/signup/EmailVerification";
import { loginApi } from "../apis/LoginApi";
import { Link, useNavigate } from "react-router-dom";
import { setUserId } from "../redux/slices/user";
import Cookies from "js-cookie";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { scratchCardApi } from "../apis/ScratchCardApi";
import WinnersList from "../components/common/WinnersList";
import { scratchCardTypeEnum } from "../types/scratchCardEnum";
import { UserStepEnum, UserTypeEnum } from "../types/userStageEnum";
import { getNextStep } from "../utils/user";
import ContactUsText from "../components/common/ContactUsText";
import { SubscriptionsGiveAway } from "../components/common/SubscriptionsGiveAway";
import UsernameRequirement from "../components/signup/UsernameRequirement";
import { validateUserNameRequirements } from "../utils/validation";
import { StyledButton } from "../styles/StyledComponents";
import { HEADER_HEIGHT } from "../components/common/Header";
import { production } from "../configs/settings";

interface SignupProps {
  skipVerification?: boolean;
  emailReceived?: string;
  scratchCardDetails?: any;
}

export default function Signup({ skipVerification, emailReceived, scratchCardDetails }: SignupProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userId, setLocalUserId] = useState("");
  const [userName, setUsername] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [showVerifyEmailStep, setShowVerifyEmailStep] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const couponCode = scratchCardDetails?.fullCode;

  const passwordRequirements = validatePassword(password, confirmPassword).passwordRequirements;
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (emailReceived) {
      setEmail(emailReceived);
    }
  }, [emailReceived]);

  async function handleSubmit(event: any) {
    event.preventDefault();

    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPasswordError("");
    setConfirmPasswordError("");
    setUserNameError("");

    const firstNameValidation = validateName(firstName);
    const lastNameValidation = validateName(lastName);

    let hasErrors = false;
    let firstErrorField: string | null = null;

    if (!firstNameValidation.isValid) {
      setFirstNameError("First " + firstNameValidation.error);
      hasErrors = true;
      firstErrorField = firstErrorField || "firstName";
    }
    if (!lastNameValidation.isValid) {
      setLastNameError("Last " + lastNameValidation.error);
      hasErrors = true;
      firstErrorField = firstErrorField || "lastName";
    }
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      hasErrors = true;
      firstErrorField = firstErrorField || "email";
    }
    if (!validatePassword(password, confirmPassword).isPasswordValid) {
      setPasswordError("Please enter a valid password");
      setConfirmPasswordError("Passwords must match and meet all requirements");
      hasErrors = true;
      firstErrorField = firstErrorField || "password";
    }
    if (!validateUserNameRequirements(userName).isUsernameValid) {
      setUserNameError("Username must be 2-16 characters and contain only letters and numbers");
      hasErrors = true;
      firstErrorField = firstErrorField || "username";
    }

    if (hasErrors) {
      // Scroll to the first error field
      const errorElement = document.getElementById(firstErrorField!);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        // Fallback: scroll to top of form if specific field isn't found
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      return;
    }

    try {
      // Handle signup with verification skipped (usually for scratch card flows)
      if (skipVerification) {
        // Prepare step progress data for scratch card users
        let stepProgress;
        if (scratchCardDetails) {
          stepProgress = {
            type: UserTypeEnum.ScratchCard,
            // Determine next step based on scratch card type
            activeStep: getNextStep(
              scratchCardDetails?.type === scratchCardTypeEnum.PromoCode || scratchCardTypeEnum.PromoReferral
                ? UserStepEnum.RedeemReward
                : UserStepEnum.CreateAccount,
              UserTypeEnum.ScratchCard
            ).nextStep,
            fullCode: scratchCardDetails?.fullCode,
          };
        }

        // Call signup API with user details
        const res = await signupApi.signUpUser(
          email,
          password,
          userName,
          firstName,
          lastName,
          skipVerification,
          stepProgress
        );

        // Handle signup response and errors
        if (!res?.success) throw new Error("Error in signing up");
        const userId = res?.user?._id;
        const jwtToken = res?.user?.jwtToken;
        if (!userId) throw new Error("No user found");
        if (!jwtToken) throw new Error("Authorization failed");

        // Set user state and cookies
        setLocalUserId(res.user._id);
        if (rememberMe) {
          Cookies.set("twoFactorDisabled", "true", { expires: Infinity });
        }
        dispatch(setUserId({ userId, jwtToken }));

        // Handle navigation based on scratch card type
        if (scratchCardDetails) {
          if (
            scratchCardDetails.type === scratchCardTypeEnum.PromoCode ||
            scratchCardDetails.type === scratchCardTypeEnum.PromoReferral
          ) {
            // Claim promo code and navigate to subscriptions
            await scratchCardApi.claimFreePromoCode({
              ownerUserId: userId,
              scratchCardFullCode: couponCode,
            });
            navigate(`/user/?tab=subscriptions&scratchCardId=${scratchCardDetails?._id}`);
          } else {
            // Navigate to redeem scratch card page
            navigate(`/redeem-scratch-card/${scratchCardDetails?.fullCode}`);
          }
        } else {
          // Default navigation to user page
          navigate("/user");
        }
        dispatch(openSnackbar({ message: "Email verified", severity: "success" }));
      } else {
        // Handle normal signup flow with email verification
        const res = await signupApi.signUpUser(email, password, userName, firstName, lastName);
        if (!res?.success) throw new Error("Error in signing up");

        // Set user state and send verification email
        setLocalUserId(res.user._id);
        await loginApi.sendEmailForSignupVerification({ userId: res.user._id });

        // Set remember me cookie if selected
        if (rememberMe) {
          Cookies.set("twoFactorDisabled", "true", { expires: Infinity });
        }

        // Show email verification step
        setShowVerifyEmailStep(true);
      }
    } catch (error: any) {
      if (!production) console.error(error);
      if (error.message.toLowerCase().includes("username already taken")) {
        setUserNameError("Username already taken");
      } else {
        dispatch(openSnackbar({ message: "Sign up failed: " + error.message, severity: "error" }));
      }
    }
  }

  return (
    <Box display={"flex"}>
      <Stack
        sx={{
          minHeight: {
            xs: "calc(100vh - " + HEADER_HEIGHT.xs + ")",
            sm: "calc(100vh - " + HEADER_HEIGHT.sm + ")",
          },
          width: "100%",
          flexDirection: { xs: scratchCardDetails ? "column" : "column-reverse", sm: "row" },
        }}
      >
        <Box
          component={Paper}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: { xs: scratchCardDetails ? "0rem" : "1rem", sm: "0" },
            width: { xs: "100%", sm: "50%" },
          }}
        >
          <Box
            sx={{
              my: 0,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: scratchCardDetails ? "flex-start" : "center",
              justifyContent: "flex-start",
            }}
          >
            {!scratchCardDetails && (
              <Avatar sx={{ m: 1, mt: { xs: 0, sm: 3 }, bgcolor: "primary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
            )}
            <Typography fontSize={"1.5rem"} fontWeight={"bold"} sx={{ m: "1rem 0" }}>
              {scratchCardDetails ? "You're almost there!" : "Sign Up"}
            </Typography>
            {scratchCardDetails && (
              <Typography fontSize={"0.8rem"} sx={{ mb: "1rem" }}>
                {scratchCardDetails?.type === scratchCardTypeEnum.PromoCode ||
                scratchCardDetails?.type === scratchCardTypeEnum.PromoReferral
                  ? "To access your digital subscriptions, please create an account below."
                  : "To see what you’ve won, please create an account below."}
              </Typography>
            )}

            {showVerifyEmailStep && userId && email ? (
              <EmailSentMessage email={email} userId={userId} />
            ) : (
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{}}>
                <TextField
                  id="firstName"
                  margin="normal"
                  required
                  fullWidth
                  label="First Name"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setFirstNameError("");
                  }}
                  error={!!firstNameError}
                  helperText={firstNameError}
                  disabled={showVerifyEmailStep}
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setLastNameError("");
                  }}
                  error={!!lastNameError}
                  helperText={lastNameError}
                  disabled={showVerifyEmailStep}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  helperText={userNameError}
                  error={!!userNameError}
                  value={userName}
                  disabled={showVerifyEmailStep}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    setUserNameError("");
                  }}
                />
                {!showVerifyEmailStep && (
                  <Box
                    sx={{
                      mt: 1,
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <UsernameRequirement
                      label="Must be between 2 and 16 characters"
                      valid={validateUserNameRequirements(userName).usernameRequirements.length && userName.length > 1}
                    />
                    <UsernameRequirement
                      label="Letters and numbers only (no spaces)"
                      valid={validateUserNameRequirements(userName).usernameRequirements.validChars}
                    />
                  </Box>
                )}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={email}
                  error={!!emailError}
                  helperText={emailError}
                  disabled={!!emailReceived || showVerifyEmailStep}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  error={!!passwordError}
                  helperText={passwordError}
                  disabled={showVerifyEmailStep}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError("");
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={confirmPassword}
                  error={!!confirmPasswordError}
                  helperText={confirmPasswordError}
                  disabled={showVerifyEmailStep}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setConfirmPasswordError("");
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {!showVerifyEmailStep && (
                  <Box
                    sx={{
                      mt: 1,
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {/* <Typography variant="body2">Password must include:</Typography> */}
                    <PasswordRequirement label={"At least 8 characters"} valid={passwordRequirements.length} />
                    {/* <PasswordRequirement
                      label={"At least one special character"}
                      valid={passwordRequirements.specialChar}
                    />
                    <PasswordRequirement
                      label={"Both upper and lower case letters"}
                      valid={passwordRequirements.upperLower}
                    /> */}
                    <PasswordRequirement label={"At least one number"} valid={passwordRequirements.number} />
                    <PasswordRequirement
                      label={"Passwords match"}
                      valid={password === confirmPassword && passwordRequirements.length}
                    />
                  </Box>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      color="primary"
                      onChange={() => {
                        setRememberMe(!rememberMe);
                      }}
                      checked={rememberMe}
                    />
                  }
                  label="Remember me"
                />
                <StyledButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    fontSize: { xs: "1rem", sm: "1.1rem" },
                  }}
                  disabled={!validatePassword(password, confirmPassword).isPasswordValid}
                >
                  Confirm
                </StyledButton>
                Already have an account? <Link to="/login">Sign In</Link>
              </Box>
            )}

            <ContactUsText styleObj={{ p: 0, pt: 2, mb: { xs: "0.4rem", sm: "0rem" } }} />
          </Box>
        </Box>

        <Box
          sx={{
            position: "relative",
            background: "linear-gradient(to bottom, #f0a725, #f05725)",
            display: "flex",
            justifyContent: "center",
            alignItems: !scratchCardDetails ? "center" : "flex-start",
            color: "white",
            flexGrow: 1,
            width: { xs: "100%", sm: "50%" },
            height: { xs: "auto", sm: "auto" },
            paddingTop: !scratchCardDetails ? { xs: "0", sm: "0rem" } : { xs: "1rem", sm: "0rem" },
          }}
        >
          {!scratchCardDetails ? (
            <WelcomeSection title="Welcome!" page="signup" />
          ) : scratchCardDetails.type === scratchCardTypeEnum.PromoCode ||
            scratchCardDetails.type === scratchCardTypeEnum.PromoReferral ? (
            <SubscriptionsGiveAway />
          ) : (
            <WinnersList />
          )}
        </Box>
      </Stack>
    </Box>
  );
}
