import { production } from "../configs/settings";
import BaseAPI from "./BaseApi";

interface ContactUsMessage {
  name: string;
  email: string;
  subject: string;
  message: string;
}

class ContactUsApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/contactUs" });
  }

  async sendContactUsMessage({ name, email, subject, message }: ContactUsMessage) {
    try {
      const result = await this.axios.post("/save", { name, email, subject, message });
      if (!result.data || !result.data.success) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      const response = error?.response?.data?.message || "No response";
      if (!production)
        console.error("@ContactUsApi.sendContactUsMessage:", { response: response }, { error: error.message });
      throw new Error(`@ContactUsApi.sendContactUsMessage: ${response}`);
    }
  }
}

export const contactUsApi = new ContactUsApi();
