import { Box, Typography, Avatar } from "@mui/material";
import { getImageUrlFromProfileImageField } from "../../utils/user";
import winnersLogo from "../../assets/images/winners-logo.png"; // Adjust the path as needed
import { useIsMobile } from "../../hooks/useIsMobile";
import { useEffect, useState } from "react";
import { scratchCardApi } from "../../apis/ScratchCardApi";
import { scratchCardUserStatusEnum } from "../../types/scratchCardEnum";
import emmaAndGabeGetPrize from "../../assets/images/emma_and_gabe_get_prize.png";

const WinnersList = () => {
  const isMobile = useIsMobile();
  const [recentRewards, setRecentRewards] = useState<any>([]);

  useEffect(() => {
    async function fetchRecentRewards() {
      const { scratchCardUsers, total } = await scratchCardApi.getPaginatedScratchCardUsers({
        page: 0,
        limit: 3,
        status: [scratchCardUserStatusEnum.Claimed, scratchCardUserStatusEnum.RewardAssigned],
        sortKey: "updatedAt",
        sortDirection: "-1",
        populate: true,
      });

      setRecentRewards(scratchCardUsers || []);
    }
    fetchRecentRewards();
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        justifyContent: "space-between",
        gap: { xs: 2, sm: 3 },
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          width: "100%",
          marginTop: { xs: 2, sm: 2 },
        }}
      >
        <Typography
          fontSize={{ xs: "1.4rem", sm: "1.5rem", md: "1.8rem" }}
          sx={{
            padding: { xs: "0.5rem", sm: "1rem" },
          }}
          fontWeight="bold"
          color="white"
        >
          MOST RECENT PRIZE WINNERS
        </Typography>

        {recentRewards.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: { xs: 1.5, sm: 2, md: 2.5 },
              width: "100%",
              margin: "0 auto",
            }}
          >
            {recentRewards.map((reward: any) => (
              <Box
                key={reward?._id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: { xs: 1.5, sm: 2, md: 3 },
                  padding: { xs: 1, sm: 1.5, md: 2 },
                  color: "black",
                  width: "100%",
                  marginX: "auto",
                  boxSizing: "border-box",
                }}
              >
                <Avatar
                  src={getImageUrlFromProfileImageField(reward?.ownerUserId?.profileImage)}
                  alt={reward?.ownerUserId?.userName}
                  sx={{
                    width: { xs: 45, sm: 55, md: 65 },
                    height: { xs: 45, sm: 55, md: 65 },
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "13rem",
                    fontSize: "1rem",
                    textAlign: "left",
                    gap: { xs: 0.5, sm: 1 },
                  }}
                >
                  <Typography
                    fontWeight="bold"
                    fontSize="inherit"
                    sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {reward?.ownerUserId?.userName || "Anonymous"}
                  </Typography>

                  <Typography
                    mt={1}
                    fontSize="inherit"
                    color="white"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {reward?.scratchCardId?.rewardId?.name || "N/A"}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Typography
            variant="body1"
            color="grey.500"
            sx={{
              minHeight: { xs: "100px", sm: "120px", md: "150px" },
              display: "flex",
              color: "black",
              alignItems: "center",
              justifyContent: "center",
              fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
            }}
          >
            No recent rewards available.
          </Typography>
        )}
      </Box>

      <Box
        component="img"
        src={emmaAndGabeGetPrize}
        alt="Winners List"
        sx={{
          width: { xs: "100%", sm: "85%", md: "80%", lg: "75%" },
          height: "auto",
          objectFit: "contain",
          mt: { xs: 2, sm: 3, md: "auto" },
          maxWidth: { xs: "26rem", sm: "30rem" },
          maskImage: "linear-gradient(to bottom, black 85%, transparent 100%)",
          WebkitMaskImage: "linear-gradient(to bottom, black 85%, transparent 100%)",
        }}
      />
    </Box>
  );
};

export default WinnersList;
